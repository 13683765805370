.color-btn {
  background: #fcf5e2 !important;
  border-radius: 24px !important;
  padding: 10px 16px !important;
  color: #f34f4f !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  border:none !important;
  text-align: left !important;
}
