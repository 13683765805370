a {
  text-decoration: none !important;
}

.notification {
  padding: 50px;
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}
/* ._loading_overlay_content {
padding-right: 350px;
padding-bottom: 350px;
} */
.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
.hidden-button {
  width: 30% !important;
}
select:required:invalid {
  color: gray;
}
.rs-loader-backdrop {
  background: rgb(255 255 255 / 70%) !important;
}
.css-tlfecz-indicatorContainer {
  padding-right: 0px !important;
}
.card-header {
  border-color: #f0f1f5;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: block;
  align-items: center;
}
.bg-color {
  background: #f34f4f;
  border-color: #f34f4f;
  color: #fff;
}
.bg-color:hover {
  background: #f34f4f;
  border-color: #f34f4f;
  color: #fff;
}
.bg-color:focus {
  background: #f34f4f;
  border-color: #f34f4f;
  color: #fff;
}
.bg-color:active {
  background: #f34f4f !important;
  border-color: #f34f4f !important;
  color: #fff !important;
}
.badge {
  font-size: 14px;
}

/* review form */

.colored-btn {
  color: #fff !important;
  background: #f34f4f !important;
  border-color: #f34f4f !important;
  border-radius: 30px !important;
  padding: 12px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.border-none {
  border: none !important;
}
.logo-img {
  width: 130px !important;
}
.form-group label {
  font-weight: 600 !important;
}
.height {
  height: 100vh;
}
.heading {
  font-weight: 600;
  font-size: 24px;
  color: #333333;
}
.sub-heading {
  font-weight: 400;
  font-size: 14px;
  color: #686868;
}
.footer-content {
  font-weight: 700;
  font-size: 14px;
  color: #f34f4f;
}
.navbar-fixed {
  position: fixed;
  z-index: 10;
  background-color: #fff;
  max-width: 100%;
}
.padding-top {
  padding-top: 50px;
}
.text-color {
  color: #f34f4f;
}
.border-radius-50 {
  border-radius: 50%;
}
.cursor{
  cursor: pointer;
}